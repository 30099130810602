import React from 'react'
import { Link, graphql } from 'gatsby'

import Img from 'gatsby-image/withIEPolyfill'
import BackgroundImage from 'gatsby-background-image'

import Layout from '../components/layout'
import SEO from '../components/seo'

import styled from 'styled-components'
import { Container, PageWrapper } from '../components/common/container'
import { Title } from '../components/common/title'
import { HeroSection } from '../components/common/hero-section'
import { CustomCard } from '../components/common/card'

import colors from '../data/color-pallette'

import Button from 'react-md/lib/Buttons/Button'
import Grid from 'react-md/lib/Grids/Grid'
import Cell from 'react-md/lib/Grids/Cell'
import breakpoints from '../data/breakpoints'

const CustomHeroSection = styled(HeroSection)`
  height: 300px;
`

const HeroTitle = styled(Title)`
  color: ${colors.white};
  margin-bottom: 12px;
`

const PricingTiersContainer = styled(Container)`
  @media (min-width: 0) {
    max-width: 800px;
  }
`

const PricingTierCard = styled(CustomCard)`
  text-align: center;
  padding-bottom: 16px;

  ul {
    padding: 0;
  }
  li {
    list-style: none;
    display: flex;

    span {
      text-align: left;
      flex: auto;
      padding: 0 16px;

      &:last-child {
        text-align: right;
      }
    }
  }
`

const TierImage = styled(Img)`
  min-height: 120px;
`

const SectionTitle = styled(Title)`
  font-size: 32px;
  margin-bottom: 12px;
  text-align: center;
  margin-top: 36px;
`

const MarketingContainer = styled.div`
  background: ${colors.secondary};
  padding: 24px;

  p {
    color: white;
    font-size: 18px;
    margin-bottom: 0;
  }

  text-align: center;
`

const MarketingTitle = styled(SectionTitle)`
  color: white;
  margin-top: 0;
`

const FeaturesCell = styled(Cell)`
  margin: 0 auto;
`
const FeaturesCard = styled.div`
  padding: 16px;

  h3 {
    font-weight: 600;
  }
`

const FeatureList = styled.ul`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`

const FeatureListItem = styled.li`
  width: 100%;

  @media (min-width: ${breakpoints.xs}) {
    width: 50%;
  }
`

const BetaWarning = styled.div`
  text-align: center;
  padding: 24px;
  background: ${colors.error};

  h3,
  p {
    color: white;
  }

  h3 {
    font-weight: 500;
    font-size: 22px;
  }
`

const IndexPage = props => {
  const {
    data: {
      heroImage,
      pricingTiers,
      features,
      site: {
        siteMetadata: { dashboardUrl },
      },
    },
  } = props
  return (
    <Layout>
      <SEO title="Pricing | StreamParrot - The best branding available for your chat overlay" />
      <PageWrapper>
        <div>
          <BackgroundImage
            fluid={{
              base64: heroImage.childImageSharp.lowRes.dataURI,
              src: heroImage.childImageSharp.hiRes.dataURI,
            }}
          >
            <CustomHeroSection>
              <HeroTitle>Pricing</HeroTitle>
            </CustomHeroSection>
          </BackgroundImage>
          <MarketingContainer>
            <MarketingTitle>
              Unlock Your Chat Overlay's Potential!
            </MarketingTitle>
            <p>Your viewers will love it.</p>
          </MarketingContainer>
          <BetaWarning>
            <h3>Warning</h3>
            <p>
              This project is in Closed Beta. We don't know how much the paid
              tiers will cost. You can help us decide during the Open Beta.
            </p>
            <a
              href={`${dashboardUrl}/login`}
              className="md-btn md-btn--flat md-btn--raised md-btn--text md-btn--raised-pressed md-pointer--hover md-background--secondary md-background--secondary-hover md-inline-block"
              secondary
              flat
              raised
            >
              Sign Up
            </a>
          </BetaWarning>
          <PricingTiersContainer>
            <SectionTitle>Price Breakdown</SectionTitle>
            <Grid>
              {pricingTiers.nodes.map(tier => (
                <Cell size={4}>
                  <PricingTierCard>
                    <TierImage
                      fluid={{
                        base64:
                          props.data[`${tier.type}Image`].childImageSharp.sqip
                            .dataURI,
                      }}
                    />
                    <h3>
                      {tier.name}
                      {tier.type === 'free' ? '' : '?'}
                    </h3>
                    <h4>${tier.type === 'free' ? 0 : 'TBD'}</h4>
                    <ul>
                      <li>
                        <span>Max Emote Slots</span>
                        <span>{tier.max_emotes}?</span>
                      </li>
                      <li>
                        <span>Max Emote Size</span>
                        <span>{tier.max_upload_kb}kb?</span>
                      </li>
                    </ul>
                    {tier.enabled}
                    {!!tier.enabled && (
                      <a
                        className="md-btn md-btn--raised md-btn--text md-btn--raised-pressed md-pointer--hover md-background--secondary md-background--secondary-hover md-inline-block"
                        href={`${dashboardUrl}/login`}
                      >
                        Sign-up
                      </a>
                    )}
                    {!tier.enabled && (
                      <Button disabled flat>
                        Coming Soon
                      </Button>
                    )}
                  </PricingTierCard>
                </Cell>
              ))}
            </Grid>
          </PricingTiersContainer>
          <PricingTiersContainer>
            <Grid>
              <FeaturesCell size={8}>
                <FeaturesCard>
                  <h3>Standard Features</h3>
                  <p>
                    All plans come with the following features out of the box.
                  </p>
                  <FeatureList>
                    {features.nodes.map(feature => (
                      <FeatureListItem>
                        {feature.frontmatter.title}
                      </FeatureListItem>
                    ))}
                  </FeatureList>
                </FeaturesCard>
              </FeaturesCell>
            </Grid>
          </PricingTiersContainer>
        </div>
      </PageWrapper>
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query($SQIP_HERO_SHAPE_COUNT: Int, $SQIP_THUMB_SHAPE_COUNT: Int) {
    site {
      siteMetadata {
        dashboardUrl
      }
    }
    heroImage: file(relativePath: { regex: "/coins2.jpeg/" }) {
      ...HeroImage
    }
    freeImage: file(relativePath: { regex: "/brass.jpeg/" }) {
      childImageSharp {
        sqip(
          numberOfPrimitives: $SQIP_THUMB_SHAPE_COUNT
          blur: 0
          width: 256
          mode: 1
        ) {
          dataURI
        }
      }
    }
    adamantiumImage: file(relativePath: { regex: "/adamantium.jpeg/" }) {
      childImageSharp {
        sqip(
          numberOfPrimitives: $SQIP_THUMB_SHAPE_COUNT
          blur: 0
          width: 256
          mode: 1
        ) {
          dataURI
        }
      }
    }
    unobtainiumImage: file(relativePath: { regex: "/unobtainium.jpeg/" }) {
      childImageSharp {
        sqip(
          numberOfPrimitives: $SQIP_THUMB_SHAPE_COUNT
          blur: 0
          width: 256
          mode: 1
        ) {
          dataURI
        }
      }
    }
    pricingTiers: allInternalPricingTiers(
      filter: { purchasable: { eq: true } }
      sort: { fields: max_emotes }
    ) {
      nodes {
        id
        type
        purchasable
        name
        enabled
        max_emotes
        max_upload_kb
      }
    }
    features: allMarkdownRemark(
      filter: { frontmatter: { postType: { eq: "feature" } } }
      sort: { order: DESC, fields: [frontmatter___date] }
      limit: 1000
    ) {
      nodes {
        html
        frontmatter {
          postType
          path
          title
          date(formatString: "MMMM DD, YYYY")
          summary
          featuredImage {
            childImageSharp {
              sqip(
                numberOfPrimitives: $SQIP_THUMB_SHAPE_COUNT
                blur: 0
                width: 256
                mode: 1
              ) {
                dataURI
              }
            }
          }
        }
      }
    }
  }
`
